#registerBackground{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    background-image: url("../../../public/background/degrade-background.jpg");
    background-size: cover;
}

#registerContainer{
    padding:25px 69px 25px 69px;
    margin: 20px 0px 20px 0px;

    width: 45%;

    border-radius: 20px;
    box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.473);

    background-color: rgba(255, 255, 255, 0.826);
}

#registerLink{
    margin-top:25px;
}

#registerLink a{
    text-decoration: none;
}

@media screen and (max-width: 1200px){
    #registerContainer{
        width: 60%;
    }
}

@media screen and (max-width: 900px){
    #registerContainer{
        width: 80%;
    }
}

@media screen and (max-width: 700px){
    #registerContainer{
        width: 90%;
    }
}