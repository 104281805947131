#contact{
    flex-grow: 1;
}

#contact a:link{
    text-decoration: none;
}

#contactHeader{
    background-image: url('../../../public/background/art-background11.jpg');
}

.contactContent{
    margin-top:20px;
    margin-bottom:20px;
    padding: 25px;

    border-bottom: 1px solid black;
}

.contactText{
    text-align: center;
    padding: 30px
}

.contactLogo{
    height: 36px;
}


@media screen and (max-width: 448px){
    #contactHeader{
        margin: 0px;
        padding: 30px 0px 30px 0px;
        font-size: 20vw;
    }

    .contactContent img{
        display:none;
    }
}