#footer{
    background-color: #f5f5f6;
    height:90px;
}

#social{
    display: flex;
    justify-content: center;
    align-items: center;
}

#social ul{
    display: flex;
    list-style-type: none;
    margin: 15px;
    padding: 0;
}

#social li{
    margin-right: 32px;
}

#social img{
    height: 40px;
}