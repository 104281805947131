#articleDate{
    text-align: right;
    color:gray;
}

#articleContainer h1{
    margin:25px 0px 13px 0px;
    padding:10px;

    background-color: rgba(151, 105, 182, 0.945);
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgb(126, 126, 126);

    text-align: center;
    font-family: 'roboto';
    font-weight: bold;
    color:rgb(255, 255, 255);
    font-style:oblique;
}

#articleContent{
    padding:20px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgb(136, 136, 136);
    background-color: rgb(244, 244, 244);
    font-family:Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 989px){
    #articleContainer h1{
        margin:15px 0px 13px 0px;
        padding: 5px;
    }

    #articleContent{
        margin:0px;
        padding:10px;
        border-radius: 2px;
        box-shadow: 2px 2px 10px rgb(136, 136, 136);
        background-color: rgb(244, 244, 244);
        font-family:Arial, Helvetica, sans-serif;
    }
}
  