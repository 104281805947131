.noPageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: salmon; 
}

#noPageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto 0px auto;
    border-radius: 1000px;
    background-color: rgb(124, 179, 216);
    width: 50%;
    height: 60%;
}
  
#noPageContent h1{
    font-size: 4em;
    font-weight: bold;
}

#noPageContent a{
    text-decoration: none;
    color:rgb(233, 233, 94)
}

#noPageContent a:hover{
    color:mediumpurple
}

