#navContainer {
    background-color: #f5f5f6;
    align-items: center;
    padding: 1.5rem;
    margin:0px;
  }
  
  #nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  
  #nav li {
    margin-right: 42px;
  }
  
  #nav a {
    color: #333;
    text-decoration: none;
    white-space: nowrap;
  }
  
  #nav a:hover {
    color: #007bff;
  }  

.active {
    font-weight: bold;
    color: #007bff;
  }

#logo{
  border-radius: 25px;
  width: 170px;
}

#languageContainer{
  padding:0px;
  display:flex;
  justify-content: flex-end;
}

@media screen and (max-width: 989px){
  #languageContainer{
    margin-top:6px;
    margin-bottom:-13px;
    justify-content: center;
  }

  #logoContainer{
    text-align: center;
    margin-bottom: 5px;
    margin-top:-13px;
  }

  #nav{
    text-align: center;
  }

  #nav li {
    margin-bottom: 12px;
    margin-right: 0px;
    border-bottom: solid 1px black;
  }
}
