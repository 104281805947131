#profileHeader{
    background-image: url('../../../public/background/profile-background.jpg');
    color:rgb(226, 230, 22);
    text-shadow: 2px 2px 10px black;
    font-weight: bold;
}

#userOptions{
    border-bottom: solid 1px black;
}

#postArticle{
    background-color:rgba(157, 60, 187, 0.767);
    font-weight: bold;
}

#manageArticles{
    background-color:rgba(32, 216, 216, 0.863);
    font-weight: bold;
}

#deleteAccount{
    background-color: rgba(236, 236, 57, 0.836);
    font-weight: bold;
}

.user-profile-options {
    transition: transform 0.3s ease-in-out;
}

.user-profile-options:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.table-profile {
    width: 80%;
    margin: auto;
    background-color: #838ff5;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .table-profile th, .table-profile td {
    padding: 15px;
    text-align: left;
  }
  
  .table-profile th {
    background-color: #e0e0e0;
    color: #333;
    font-weight: 500;
  }
  
  .table-profile td {
    color: #666;
  }
  
  .table-profile tr:nth-child(even) {
    background-color: #f0f0f0;
  }
  
  .table-profile tr:nth-child(odd) {
    background-color: #fff;
  }
  .table-profile td:first-child {
    width: 30%;
  }
  
  .table-profile td:nth-child(2) {
    width: 70%;
  }

@media screen and (max-width: 448px){
    #profileHeader{
        font-size: 20vw;
    }
}


